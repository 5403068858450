@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';














































































































.activity-mapping-combobox {
  max-width: 50rem !important;
}

.activity-mapping__found-activities {
  position: sticky;
  top: 0;
  max-width: 30rem;
  background-color: transparent !important;
}
