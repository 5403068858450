@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';























































































.mapping-start__selection-card {
  &--xl{
    min-height: 12rem;
  }
  &--xl-and-down {
    min-height: 6rem;
  }
}
