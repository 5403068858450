@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';






































































































.column-mapping-date-selector {
  max-width: 30rem !important;
}

.column-mapping-hint {
  font-size: size(12);
}
