@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































































































































.bulk-import-verification__no-mapping-table__no-mapping-column {
  color: $error;
}
.impersonation-selection-table__table {
  .v-data-table__wrapper {
    flex-grow: 1;
    > * tbody {
      overflow-y: scroll;
    }
  }
}
