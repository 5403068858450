@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';


















































































































































































































































































































































































































































.create-import-mapping__stepper__h-100 {
  .v-stepper__wrapper {
    height: 100%;
    overflow-y: auto;
  }
}
