@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

































































































































.import-activities-table__row--error {
  color: $itaa-red;
  cursor: pointer;
}
