@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';







































.import-mapping-file-selection {
  &--max {
    min-height: 35rem;
  }
}
