@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';










































































.mapping-verification-step__data-table {
  .v-data-table__wrapper {
    height: 100%;
    overflow-y: auto;
  }
  > * table {
    overflow-y: auto;
  }
}
