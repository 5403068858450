@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';




























































.itaa-stepper__header {
  font-size: $tertiary-heading-size;
  min-height: 4.5rem !important;
}
